<script setup lang="ts">
const localePath = useLocalePath()
defineProps({
  href: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
})
</script>
<template>
  <Container class="screen-content px-2 py-16">
    <div class="bordershadow-gray mt-1 rounded-lg bg-white p-2">
      <NuxtLink
        :to="localePath(href)"
        class="!bordershadow-main group relative block w-full overflow-hidden rounded !border-0 !p-0"
      >
        <ClientOnly>
          <Gradient id="gradient" class="h-32 w-full transition duration-500 group-hover:scale-125 md:h-64" />
        </ClientOnly>
        <div
          class="absolute inset-0 flex flex-col items-center justify-center transition duration-300 will-change-transform group-hover:scale-110"
        >
          <Heading size="large">
            {{ label }}
          </Heading>
        </div>
      </NuxtLink>
    </div>
  </Container>
</template>
